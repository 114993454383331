<div class="navbar bg-dark-blue mt-2">
  <div class="container linkNoUnderline">
    <div class="row w-100px">
      <div class="col-3">

        <div class="row">
          <a class="navbar-brand" href="#">
            <img src="/assets/img/sage-logo.svg" width="78" height="30" alt="Sage Logo" />
          </a>
        </div>
        <small class="text-white mb-0 font-weight-bold">Connect with Us</small>
        <ul ngbNav #nav="ngbNav" class="d-flex mb-2 border-bottom border-top py-1">
          <li ngbNavItem class="pb-2">
            <a ngbNavLink class="px-1 py-0" href="https://www.facebook.com/AccpacIndonesia" target="_blank" aria-label="Facebook">
              <div style="width: 12px; height: 12px;">
                <img class="navbar-nav-svg" src="/assets/icon/facebook-white.svg" alt="Facebook" />
              </div>
            </a>
          </li>
          <li ngbNavItem class="pb-2">
            <a ngbNavLink class="px-1 py-0" href="https://www.linkedin.com/company/sage300microtek/" target="_blank" aria-label="LinkedIn">
              <div style="width: 20px; height: 20px;">
                <img class="navbar-nav-svg" src="/assets/icon/linkedin-white.svg" alt="LinkedIn" />
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-3 text-top pt-3">
        <div class="text-white mb-3">
          <div class="small font-weight-bolder text-secondary p-1">Products</div>
          <div class="small font-weight-bold p-1"><a routerLink="/products/sage300" class="text-white">Accounting</a></div>
          <div class="small font-weight-bold p-1"><a routerLink="/products/sagecrm" class="text-white">Customer Relationship Management</a></div>
          <div class="small font-weight-bold p-1"><a routerLink="/products/hrpay" class="text-white">HR &amp; Payroll </a></div>
        </div>
      </div>
      <div class="col-3 text-top pt-3">
        <div class="text-white mb-3">
          <div class="small font-weight-bolder text-secondary p-1">Support &amp; Training</div>
          <div class="small font-weight-bold p-1"><a routerLink="/support" class="text-white">Support</a></div>
          <div class="small font-weight-bold p-1"><a routerLink="/training" class="text-white">Training</a></div>
          <div class="small font-weight-bold p-1"><a routerLink="/products/sagecover" class="text-white">Sage Cover</a></div>
        </div>
      </div>
      <div class="col-3 text-top pt-3">
        <div class="text-white mb-3">
          <div class="small font-weight-bolder text-secondary p-1">Company</div>
          <div class="small font-weight-bold p-1"><a routerLink="/about-us" class="text-white">About Us</a></div>
          <div class="small font-weight-bold p-1"><a routerLink="/awards" class="text-white">Awards</a></div>
          <div class="small font-weight-bold p-1"><a routerLink="/contact-us" class="text-white">Contact Us</a></div>
        </div>
      </div>
      <div class="row w-100">
        <div class="col text-center">
          <small class="text-white">Copyright &copy; 2020 Microtek Informa Solusindo - Sage Premier Partner for Indonesia</small>
        </div>
      </div>
    </div>
  </div>
</div>
