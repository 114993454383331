import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class IpAddressService {

  constructor(private http: HttpClient) { }

  public GetIpAddress(): string {

    let ipAddress: string;
    this.http.get("https://api.ipify.org/?format=json")
      .subscribe((res: any) => {
        ipAddress = res.ip;
      })

    return ipAddress;
  }
}
