import { FormDefinition } from './form-definition';
import { DataType } from '../dynamic-form/form-definition';

export const FormDefinitions: {id: string, value: FormDefinition[]} [] = [
  {
    id: "emailUs",
    value: [
      new FormDefinition(
        {
          value: "",
          key: "title",
          name: "title",
          label: "Title",
          placeholder: "Select your title",
          minLength: 0,
          maxLength: 8,
          dataType: DataType.Option,
          optionList: [
            { key: "Mr", value: "Mr" },
            { key: "Mrs", value: "Mrs" },
            { key: "Ms", value: "Ms" },
            { key: "Miss", value: "Miss" },
            { key: "NA", value: "Prefer not to say" },
          ],
        }),
      new FormDefinition(
        {
          key: "firstName",
          name: "given-name",
          label: "First Name",
          placeholder: "Enter first name",
          minLength: 2,
          maxLength: 80,
          dataType: DataType.String,
        }),
      new FormDefinition(
        {
          key: "lastName",
          name: "family-name",
          label: "Last Name",
          placeholder: "Enter last name",
          minLength: 2,
          maxLength: 80,
          dataType: DataType.String,
        }),
      new FormDefinition(
        {
          key: "company",
          name: "company",
          label: "Company",
          placeholder: "Enter company or organisation",
          minLength: 0,
          maxLength: 80,
          dataType: DataType.String,
        }),
      new FormDefinition(
        {
          key: "contactNumber",
          name: "tel",
          label: "Contact Number",
          placeholder: "Enter phone or mobile Number",
          minLength: 0,
          maxLength: 80,
          dataType: DataType.Telephone,
        }),
      new FormDefinition(
        {
          key: "email",
          name: "email",
          label: "Email Address",
          placeholder: "Enter email",
          helpText: "We are committed to keeping your e-mail address confidential.",
          minLength: 5,
          maxLength: 80,
          dataType: DataType.Email,
        }),
      new FormDefinition(
        {
          key: "enquiry",
          name: "enquiry",
          label: "Enquiry",
          placeholder: "Details of your enquiry",
          minLength: 5,
          maxLength: 5000,
          dataType: DataType.String,
        }),
    ]
  },
];
