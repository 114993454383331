import { Award } from './product';

export const MisAward: Award = 
  {
    awardImages: [
      {
        imgsrc: "/assets/img/awards/2019-Platinum-Partner.jpg",
        imgalt: "Top Partner Award 2019",
        header: "Top Partner Award 2019",
        description: "Platinum Elite - Top Partner Award 2019"
      },
      {
        imgsrc: "/assets/img/awards/2005-Partner.jpg",
        imgalt: "Premier Solution Provider 2005",
        header: "Premier Solution Provider 2005",
        description: "Sage Vision - Premier Solution Provider 2005"
      },
      {
        imgsrc: "/assets/img/awards/2005-Partner-Asia.jpg",
        imgalt: "Asia Provider 2005",
        header: "Asia Producers of The Year 2005",
        description: "Sage Vision - Top Ten Asia Producers of The Year 2005"
      },
      {
        imgsrc: "/assets/img/awards/2005-Provider-Solution.jpg",
        imgalt: "Asia Solution Provider 2005",
        header: "Premier Solution Provider 2005",
        description: "Sage Vision - Premier Solution Provider 2005"
      },
      {
        imgsrc: "/assets/img/awards/2005-Sage-Cover.jpg",
        imgalt: "Sage Cover 2005",
        header: "Sage Cover Partner of The Year 2005",
        description: "Sage Vision - Sage Cover Partner of The Year 2005"
      },
      {
        imgsrc: "/assets/img/awards/2006-Partner.jpg",
        imgalt: "Asia Top 10 Partner 2006",
        header: "Asia Top 10 Partner 2006",
        description: "Sage Vision - Asia Top 10 Partner 2006"
      },
      {
        imgsrc: "/assets/img/awards/2006-Partner-Asia.jpg",
        imgalt: "Asia Premier Partner 2006",
        header: "Asia Premier Partner 2006",
        description: "Sage Vision - Asia Premier Partner 2006"
      },
      {
        imgsrc: "/assets/img/awards/2007-Sage-Premier.png",
        imgalt: "Asia Premier Partner 2007",
        header: "Asia Premier Partner 2007",
        description: "Sage Vision - Asia Premier Partner 2007"
      },
      {
        imgsrc: "/assets/img/awards/2016-CS.jpg",
        imgalt: "Customer Service Excellence 2016",
        header: "Customer Service Excellence 2016",
        description: "Sage Asia - Customer Service Excellence 2016"
      },
      {
        imgsrc: "/assets/img/awards/2016-Partner.jpg",
        imgalt: "Platinum Partner 2016",
        header: "Platinum Partner 2016",
        description: "Sage Asia - Platinum Partner 2016"
      },
      {
        imgsrc: "/assets/img/awards/2016-Submit-Asia.png",
        imgalt: "Asia Partner Summit 2016",
        header: "Asia Partner Summit 2016",
        description: "Sage Asia - Partner Summit 2016"
      },
      {
        imgsrc: "/assets/img/awards/2018-Partner.png",
        imgalt: "Top Partner 2018",
        header: "Top Partner 2018",
        description: "Sage Asia - Top Partner 2018"
      },
      {
        imgsrc: "/assets/img/awards/2019-Excellence.png",
        imgalt: "Top Excellence Partner 2019",
        header: "Top Excellence Partner 2019",
        description: "Sage - Top Excellence Partner Asia and Indonesia 2019"
      },
      {
        imgsrc: "/assets/img/awards/Peresoft-Award.jpg",
        imgalt: "Peresoft Award",
        header: "Peresoft Award",
        description: "Peresoft Award Asia"
      }
    ]
  };
