<div class="container">
  <h3 class="mb-5 text-center">{{title}}</h3>

  <div>
    <form (ngSubmit)="submit()" [formGroup]="customFormGroup">

      <div *ngFor="let formDefinition of customFormControls">
        <app-form-control [formDefinition]="formDefinition" [customFormGroup]="customFormGroup"></app-form-control>
      </div>

      <small class="form-text text-secondary mb-5 mt-3" *ngIf="additionalText">
        {{additionalText}}
      </small>

      <ng-content></ng-content>

      <div class="d-flex flex-row-reverse">
        <button type="submit" class="btn btn-primary" [disabled]="!customFormGroup.valid || isProgress">
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="isProgress"></span>
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
