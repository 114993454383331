import { Component, ViewEncapsulation, Input, Output, ViewChild, ElementRef, AfterViewInit, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { Modal } from '../modal';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class ModalComponent implements AfterViewInit {

  @Output() onClosed: EventEmitter<string> = new EventEmitter();

  @ViewChild('content')
  content: ElementRef;

  closeResult: string;

  @Input() modal: Modal = new Modal();

  public activeModal: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngAfterViewInit() {
    this.activeModal = this.modalService.open(this.content, { centered: true });

    // Trigger Events
    this.activeModal.result.then(
      (result) => {
        this.onClosed.emit(result);
      }, (reason) => {
        this.onClosed.emit(reason);
      });
  }
}
