import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild, EventEmitter, Output, Injectable, Inject, ChangeDetectorRef } from '@angular/core';
import { FormComponent } from '../dynamic-form/form.component';
import { OnExecuteData, ReCaptchaV3Service } from 'ng-recaptcha';
import { firstValueFrom, Subscription } from 'rxjs';
import { Modal } from '../modal';
import { EmailService } from '../email.service';
import { EmailRequestItem } from '../email';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-emailus',
    templateUrl: './emailus.component.html',
    styleUrls: ['./emailus.component.scss'],
    standalone: false
})

@Injectable({
  providedIn: 'root'
})

export class EmailUsComponent implements OnInit, OnDestroy, AfterViewInit {

  public readonly executionLog: OnExecuteData[] = [];
  // recaptcha
  private recentToken: string = ''
  private singleExecutionSubscription: Subscription;

  public successCode: number = 0;

  public formDefinitionId: string = "emailUs";

  @ViewChild(FormComponent) formComponent: FormComponent;

  @Output() onSucceed: EventEmitter<any> = new EventEmitter();

  ngAfterViewInit() {

  }

  constructor(private recaptchaV3Service: ReCaptchaV3Service, private emailService: EmailService, private cdr: ChangeDetectorRef) { }

  public ngOnInit() {
    this.getRecaptchaToken('submit');
  }

  public ngOnDestroy() {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

  // After the user click the submit
  public async executeAction(executionData: any): Promise<void> {

    try {

      const formGroup: UntypedFormGroup = executionData.value;

      const requestItem: EmailRequestItem = new EmailRequestItem();
      requestItem.SenderEmailAddress = formGroup.get('email').value;
      requestItem.Subject = 'Request Information From ' + formGroup.get('firstName').value + ' ' + formGroup.get('lastName').value;
      requestItem.Message = JSON.stringify(formGroup.getRawValue());
      requestItem.Token = this.recentToken;

      const data = await firstValueFrom(this.emailService.submitSupportEmail(requestItem));

      // Reset the ReCaptcha Token
      this.getRecaptchaToken('submit');
      if (data[0].Success) {
        this.successCode = 2;
        // Clear the Forms
        this.formComponent.clearFormValues();
      } else {
        this.successCode = 1;
      }

      this.formComponent.isProgress = false;
    } catch (e) {
      console.log(e);
      this.successCode = 1;
    }
    finally{
      this.formComponent.isProgress = false;
      this.cdr.detectChanges();
    }
  }

  // create function to get the recaptcha key based on event
  getRecaptchaToken(action) {

 this.singleExecutionSubscription = this.recaptchaV3Service.execute(action)
  .subscribe({
    next: (response) => {
      this.recentToken = response;
    },
    error: (error) => {
      console.error("Error getting recaptcha", error);
      this.ngOnDestroy();
    }

  });
  }

  public formatToken(token: string): string {
    if (!token) {
      return '(empty)';
    }

    return `${token.substring(0, 7)}...${token.substring(token.length - 7)}`;
  }

  get successModal(): Modal {
    const modal = new Modal();

    modal.title = "Thank you for contacting us.";
    modal.description = "<p>We have received your enquiry and will respond to you within 24 hours. \
                            For urgent enquiries, please call us on one of the telephone numbers. \
                        </p>";
    return modal;
  }


  get failedModal(): Modal {
    const modal = new Modal();

    modal.title = "There is an unexpected error.";
    modal.description = "<p>We have not received your enquiry due to unexpected error. \
                            Please try again or call us on one of the telephone numbers. \
                        </p>";
    return modal;
  }

  public succesModalClosed(): void {
    this.onSucceed.emit(this.formComponent);
    this.successCode = 0;
  }

  public failedModalClosed(): void {
    this.successCode = 0;
  }

}
