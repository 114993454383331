<div>
  <div class="bg-light-grey text-black-70 d-flex align-items-center">
    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="p-2"><h3>Contact Us</h3></div>
        <div class="ml-auto p-2">
          <a class="btn btn-success text-white" role="button" aria-pressed="true" href="tel:021-750-7886"><img src="../assets/img/local_phone-white-24dp.svg" alt="Phone" /> 021 750-7886</a>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-image-bar" style="background-image: url(https://picsum.photos/id/1/900/400);">
    <div class="container">
      <div class="bg-image-bar-info bg-dark-blue">
        <div>
          <h2>Contact Microtek</h2>
          <p class="pt-2">
            Microtek has more than hundred customers in indonesia with over 30 years of experience helping business succeed.
            Let us help you, too.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-light-grey text-black-70 py-3">
    <div class="container">
      <div class="d-flex flex-column">
        <div class="text-center pb-2">
          <h3>How can we help you today?</h3>
        </div>
      </div>
      <div class="card-group">
        <!-- Email -->
        <div class="card border-0 bg-dark-blue mt-3">
          <div class="d-flex justify-content-center">
            <img class="card-img-top py-4" style="width: 10rem;" src="../assets/img/sage-crowd-white.svg" alt="People" />
          </div>
          <div class="card-body text-white px-4">
            <h4 class="card-title">Need help with your sage solution? Find the answer you need quickly</h4>
            <p class="card-text py-4">
              <button type="button" class="btn btn-outline-light font-weight-bold" (click)="open(content)">Contact Support</button>
            </p>
          </div>
        </div>

        <!-- Phone -->
        <div class="card border-0 bg-dark-blue mt-3" >
          <div class="d-flex justify-content-center">
            <img class="card-img-top py-4" style="width: 10rem;" src="../assets/img/sage-people-chat-white.svg" alt="People" />
          </div>
          <div class="card-body text-white px-4">
            <div class="card-title">
              <h4>
                Contact Number
              </h4>
              <h5>021 750-7886</h5>
              <br /><strong>Our Opening Hours</strong>
              <br />Monday to Friday
              <br />8am-5PM
              <br />Close on Public Holidays
            </div>
            <p class="card-text py-4">
              <a href="tel:021-750-7886" target="_blank" class="btn btn-outline-light text-white font-weight-bold" role="button" aria-pressed="true">Call Us</a>
            </p>
          </div>
        </div>

        <!-- Location -->
        <div class="card border-0 bg-dark-blue mt-3" >
          <div class="d-flex justify-content-center">
            <img class="card-img-top py-4" style="width: 10rem;" src="../assets/img/sage-business-scale-white.svg" alt="People" />
          </div>
          <div class="card-body text-white px-4">
            <div class="card-title">
              <h4>Our Location</h4>
              <address>
                <strong>Microtek Informa Solusindo</strong>
                <br />Golden Plaza Block E-15
                <br />R.S Fatmawati - 15
                <br />RT.8/RW.6
                <br />Cilandak
                <br />Jakarta Selatan 12420
                <br />Indonesia
              </address>
            </div>
            <p class="card-text py-4">
              <a href="https://goo.gl/maps/CCCw3RN6jsYqLaXu5" target="_blank" class="btn btn-outline-light text-white font-weight-bold" role="button" aria-pressed="true">Direction</a>
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>


  <div class="text-black-70 py-3">
    <div class="container">
      <div class="d-flex flex-column">
        <div class="text-center pb-5">
          <h3>Connect with Microtek</h3>
          <p>We're here to help your cause and story and make your voice heard. Join our community today.</p>
        </div>
      </div>
      <div class="card-group">

        <!-- Facebook -->
        <div class="card border-0" style="min-width: 10rem; max-width: 20rem">
          <div class="w-100 px-4">
            <img class="" src="../assets/img/sage-facebook.png" style="height:72px; width:72px;" alt="facebook" />
          </div>
          <div class="card-body px-4">
            <h4 class="card-title">Facebook</h4>
            <p class="card-text py-3">
              Like us on Facebook for expert advice, encouragement and inspiration from other growing businesses all over the indonesia.
            </p>

            <a href="https://www.facebook.com/AccpacIndonesia" target="_blank" class="btn btn-outline-light font-weight-bold border rounded" role="button" aria-pressed="true">Like Us on Facebook</a>
          </div>
        </div>

        <!-- Linked In -->
        <div class="card border-0" style="min-width: 10rem; max-width: 20rem">
          <div class="w-100 px-4">
            <img class="" src="../assets/img/sage-linkedin.png" style="height:72px; width:72px;" alt="instagram" />
          </div>
          <div class="card-body px-4">
            <h4 class="card-title">Instagram</h4>
            <p class="card-text py-3">
              Follow Microtek Informa Solusindo on LinkedIn for a deeper look at topics that matter.
            </p>

            <a href="https://www.linkedin.com/company/Sage300Microtek" target="_blank" class="btn btn-outline-light font-weight-bold border rounded" role="button" aria-pressed="true">Connect with Us on LinkedIn</a>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span style="color:black" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-emailus (onSucceed)="onSucceed($event)"></app-emailus>
  </div>
</ng-template>
