import { Component, Input, OnInit } from '@angular/core';
import { Message } from '../message';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
    standalone: false
})
export class MessagesComponent implements OnInit {

  _messages = [];

  get messages(): Message[] {
    return this._messages;
  }

  @Input('messages')
  set messages(value: Message[]) {
    if (value === undefined) {
      this._messages = [];
    }
    else {
      value.sort((a, b) => b.type - a.type);
      this._messages = value;
    }
  }

  constructor() {}

  ngOnInit() {
    this._messages = [];
  }

  close(message: Message) {
    this.messages.splice(this.messages.indexOf(message), 1);
  }

}
