<div [formGroup]="customFormGroup" class="form-group" [class]="formDefinition.cssClass">
  <label [for]="formDefinition.key">{{formDefinition.label}}</label>

  <div>

    <div class="input-group">

      <div class="input-group-prepend">
        <span class="input-group-text bg-danger" style="padding:unset" *ngIf="formDefinition.minLength > 0">
          &nbsp;
        </span>
      </div>

      <!-- Drop Down -->
      <select *ngIf="formDefinition.controlType == ControlType.Dropdown" [id]="formDefinition.key" class="form-control" [name]="formDefinition.name" [attr.aria-describedby]="getHelpText"
              [formControlName]="formDefinition.key">
        <option value="" disabled selected *ngIf="formDefinition.placeholder">{{formDefinition.placeholder}}</option>
        <option *ngFor="let option of formDefinition.optionList" [value]="option.key">{{option.value}}</option>
      </select>

      <!-- Text Box -->
      <!-- Single Line Text Box-->
      <input *ngIf="formDefinition.controlType == ControlType.Textbox && formDefinition.maxLength <= 80" [type]="formDefinition.inputType" class="form-control" [id]="formDefinition.key" [placeholder]="formDefinition.placeholder"
             [name]="formDefinition.name" [formControlName]="formDefinition.key" [value]="formDefinition.value" [attr.aria-describedby]="getHelpText"
             [ngClass]="{'is-invalid':  isValid == 0, 'is-valid':  isValid == 1}" />

      <!-- Multi Line Text Box -->
      <textarea *ngIf="formDefinition.controlType == ControlType.Textbox && formDefinition.maxLength > 80" [rows]="6" class="form-control" [id]="formDefinition.key" [placeholder]="formDefinition.placeholder"
                [name]="formDefinition.name" [formControlName]="formDefinition.key" [attr.aria-describedby]="getHelpText"
                [ngClass]="{'is-invalid': isValid == 0, 'is-valid': isValid == 1}">{{formDefinition.value}}</textarea>

      <div *ngIf="formDefinition.controlType == ControlType.Textbox && formDefinition.maxLength > 0" class="input-group-prepend">
        <span class="input-group-text input-group-text-count">{{valueLength + "/" + formDefinition.maxLength }}</span>
      </div>

    </div>

  </div>

  <small [id]="getHelpText" class="form-text text-secondary" *ngIf="formDefinition.helpText && formDefinition.helpText.length > 0">{{formDefinition.helpText}}</small>

  <app-messages [messages]="messages">

  </app-messages>
</div>
