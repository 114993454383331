<div>
  <div class="bg-light-grey text-black-70 d-flex align-items-center">
    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="p-2"><h3>Support</h3></div>
        <div class="ml-auto p-2">
          <a class="btn btn-success text-white" role="button" aria-pressed="true" href="tel:021-750-7886"><img src="../assets/img/local_phone-white-24dp.svg" alt="Phone" /> 021 750-7886</a>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-image-bar" style="background-image: url(https://picsum.photos/id/370/900/400);">
    <div class="container">
      <div class="bg-image-bar-info">
        <div>
          <h2>How Microtek can help you</h2>
          <p class="pt-2">
            We've supported businesses around the indonesia for over 35 years. We're very proud to win various awards over the years – for offering great customer support.
            We'll continue to find ways to improve and deliver a world-class service.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-light-grey text-black-70 py-3">
    <div class="container">
      <div class="d-flex flex-column">
        <div class="text-center">
          <h4>Let's get started!</h4>
        </div>
        <div class="text-center">
          <p>
            As a complete solution provider, Microtek Informa Solusindo offer various services to help you get the most from the software.
          </p>
        </div>
      </div>
    </div>

    <!-- Training -->
    <div class="row bg-dark-blue my-2">
      <div class="col">
        <div class="container">
          <div class="card text-white bg-dark-blue border-0 rounded-0" style="min-width: 12rem;">
            <div class="d-flex align-items-center">

              <div class="p-2"><img src="../assets/img/sage-book-tag-white.svg" alt="Training" /></div>

              <div class="p-2 flex-fill">
                <div class="card-body">
                  <h5 class="card-title">Training</h5>
                  <div class="card-text">
                    <h6>Learn how to user your Sage software to:</h6>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item bg-transparent text-white">Greater efficiency gains per staff dollar spent</li>
                      <li class="list-group-item bg-transparent text-white">Maximises returns on investments in systems</li>
                      <li class="list-group-item bg-transparent text-white">Happier educated workforce</li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Consultancy -->
    <div class="row my-2">
      <div class="col">
        <div class="container">
          <div class="card text-black-70 bg-light-grey border-0 rounded-0" style="min-width: 12rem;">
            <div class="d-flex align-items-center">

              <div class="p-2"><img src="../assets/img/sage-crowd.svg" alt="Consultancy" /></div>

              <div class="p-2 flex-fill">
                <div class="card-body">
                  <h5 class="card-title">Consultancy</h5>
                  <div class="card-text">
                    <h6>We have a team of experienced consultants who can provide the following services:</h6>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item bg-transparent">Initial Software Configuration - maximise effeciency</li>
                      <li class="list-group-item bg-transparent">Remote Consulting - provide a service from the comfort of your office</li>
                      <li class="list-group-item bg-transparent">Onsite Consulting - have a consultant visit your office to answer your questions</li>
                      <li class="list-group-item bg-transparent">Custom Report Writing - create or customise reports and expressions</li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Development -->
    <div class="row bg-dark-green my-2">
      <div class="col">
        <div class="container">
          <div class="card text-white bg-dark-green border-0 rounded-0" style="min-width: 12rem;">
            <div class="d-flex align-items-center">

              <div class="p-2"><img src="../assets/img/sage-app-checked-white.svg" alt="Development" /></div>

              <div class="p-2 flex-fill">
                <div class="card-body">
                  <h5 class="card-title">Custom Development</h5>
                  <div class="card-text">
                    <h6>A team of specialise in development and integration projects for your Sage Software</h6>
                    <ul class="list-group list-group-flush ">
                      <li class="list-group-item bg-transparent text-white">Cost-effective customisations to perfectly refine your ERP Solution</li>
                      <li class="list-group-item bg-transparent text-white">Experienced consultants and system architects</li>
                      <li class="list-group-item bg-transparent text-white">Small teams focused on delivering best results</li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="bg-light-grey text-black-70 d-flex align-items-center p-5">
    <div class="container">
      <app-emailus></app-emailus>
    </div>
  </div>
</div>
