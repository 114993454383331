import {
  Injectable,
  Inject
} from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {
  Observable,
  throwError
} from 'rxjs';
import {
  catchError,
  retry
} from 'rxjs/operators';
import {
  EmailRequestItem,
  EmailResponseItem
} from './email';
import {
  APP_CONFIG,
  AppConfig
} from './config/app.config';
import {
  IpAddressService
} from './ipaddress.service';

@Injectable({
  providedIn: 'root'
})

export class EmailService {

  private pathAPI = this.config.apiEndpoint;

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig,
    private ipAddressService: IpAddressService) { }

  public submitSupportEmail(requestItem: EmailRequestItem) {

    // Submission Logic Add the Token
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*'
      })
    };

    let submitUrl = this.pathAPI + '/email/sendEmail';
    let ipAddress = this.ipAddressService.GetIpAddress();
    let request = [requestItem];

    requestItem.UserIpAddress = ipAddress;

    return this.http.post<EmailResponseItem[]>(submitUrl, request, headers)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(`An error occurred: ${error.error.message}`);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.error)}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Unexpected error has occurred. Please try again later.'));
  }

}
