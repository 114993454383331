import { Injectable } from '@angular/core';
import { Message } from './message';
import { MessageType } from './message';

@Injectable({ providedIn: 'root' })
export class MessageService {
  messages: Message[] = [];

  add(type: MessageType, message: string) {
    this.messages.push(new Message(type, message));
  }

  clear() {
    this.messages = [];
  }
}
