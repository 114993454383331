import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/** Bootstrap **/
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppHeaderNavComponent } from './app.header-nav.component';
import { AppFooterNavComponent } from './app.footer-nav.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';

/** Parts */
import { MessagesComponent } from './messages/messages.component';
import { EmailUsComponent } from './messages/emailus.component';
import { ModalComponent } from './messages/modal.component';

/** Config */
import { AppConfigModule } from './config/app.config';

/** STATIC PAGES */
import { AboutUsComponent } from './static/about-us.component';
import { SupportComponent } from './static/support.component';
import { ContactUsComponent } from './static/contact-us.component';
import { AwardsComponent } from './static/awards.component';
import { TrainingComponent } from './static/training.component';
import { MainComponent } from './dynamic/main.component';


/** Forms */
import { FormControlComponent } from './dynamic-form/form-control.component';
import { FormComponent } from './dynamic-form/form.component';

/** MATERIAL DESIGN */
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

/** ReCaptcha */
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CorsInterceptor } from './corsInterceptors';

@NgModule({ declarations: [
        FormControlComponent,
        FormComponent,
        AppComponent,
        AppHeaderNavComponent,
        AppFooterNavComponent,
        ProductDetailComponent,
        MessagesComponent,
        EmailUsComponent,
        ModalComponent,
        AboutUsComponent,
        SupportComponent,
        ContactUsComponent,
        AwardsComponent,
        TrainingComponent,
        MainComponent
    ],
    exports: [
        CommonModule,
        /** MATERIAL DESIGN */
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        PortalModule,
        ScrollingModule,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        RecaptchaV3Module,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        AppConfigModule], providers: [
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeQb7AZAAAAAHkOWJmlNijiSrcKVyiH8ZygVImx' },
        provideHttpClient(withInterceptorsFromDi()),
        { provide: HTTP_INTERCEPTORS, useClass: CorsInterceptor, multi: true }
    ] })

export class AppModule { }
