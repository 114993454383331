
export enum MessageType {
  Error,
  Warning,
  Information,
  Success
}

export class Message {
  type: MessageType;
  message: string;

  static successType = 'success';
  static infoType = 'info';
  static warningType = 'warning';
  static dangerType = 'danger';

  constructor(type: MessageType, message: string) {
    this.type = type;
    this.message = message || "";
  }

  get alertType(): string{
    switch (this.type) {
      case MessageType.Error:
        return Message.dangerType;
       case MessageType.Warning:
        return Message.warningType;
      case MessageType.Success:
        return Message.successType;
      default:
        return Message.infoType;
    }
  }
}
