import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FormDefinition } from './form-definition';

import { FormService } from './form.service';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    standalone: false
})

export class FormComponent implements OnInit {

  private _formDefinitionId: string;

  @Input() title: string = 'Complete the form below';
  @Input() additionalText: string = '';

  get formDefinitionId(): string {
    return this._formDefinitionId;
  }

  @Input() set formDefinitionId(definitionId: string) {
    if (definitionId !== this._formDefinitionId) {
      this.initForm(definitionId);
    }

    this._formDefinitionId = definitionId;
  };

  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  customFormGroup: UntypedFormGroup;
  customFormControls: FormDefinition[];

  @Input() isProgress: boolean = false;

  jsonResults = '';

  constructor(private formService: FormService) { }

  ngOnInit() {
    this.initForm(this.formDefinitionId);
  }

  private initForm(definitionId: string): void {

    let group: any = {};

    // Build the Form Group for the Controls
    let _formControls: any = {};
    this.formService.getFormDefinitions(definitionId)
      .subscribe(formDefinition => _formControls = formDefinition as FormDefinition[]);

    this.customFormControls = _formControls;

    this.customFormControls.forEach(_formControl => {
      group[_formControl.key] = new UntypedFormControl(_formControl.value, _formControl.validators)
    });

    this.customFormGroup = new UntypedFormGroup(group);
  }

  public clearFormValues(): void {
    this.customFormGroup = null;

    this.initForm(this.formDefinitionId);

    this.isProgress = false;
  }

  public submit(): void {

    this.isProgress = true;

    let object = { key: "submit", value: this.customFormGroup };

    this.onSubmit.emit(object);
  }
}
