import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, ValidationErrors, AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { FormDefinition, DataType } from './form-definition';
import { ControlType } from './form-definition';
import { Message } from '../message';
import { MessageType } from '../message';

@Component({
    selector: 'app-form-control',
    templateUrl: './form-control.component.html',
    styleUrls: ['./form-control.component.scss'],
    standalone: false
})

export class FormControlComponent implements OnInit {

  private _formDefinition: FormDefinition;

  get formDefinition(): FormDefinition {
    return this._formDefinition;
  };

  @Input()
  set formDefinition(value: FormDefinition) {
    this.reset();
    this._formDefinition = value;
  }

  private _customFormGroup: UntypedFormGroup;
  get customFormGroup(): UntypedFormGroup {
    return this._customFormGroup;
  };

  @Input()
  set customFormGroup(value: UntypedFormGroup) {
    this.reset();
    this._customFormGroup = value;
  }

  public messages: Message[];

  ControlType = ControlType;

  ngOnInit() {
    this.customFormControl.valueChanges.subscribe(val => {
      this.onChange(val);
    });
  }

  reset(): void {
    this.messages = undefined;
  }

  onChange(val: any) {
    this.messages = [];
    this.addMessage(this.customFormControl.errors);
  }

  get customFormControl(): UntypedFormControl {
    return this.customFormGroup.get(this.formDefinition.key) as UntypedFormControl;
  }

  get isValid(): number {
    if (this.messages === undefined) {
      return -1;
    }

    return this.customFormGroup.controls[this.formDefinition.key].valid ? 1 : 0;
  }

  get getHelpText(): string {
    if (this.formDefinition.helpText.length > 0) {
      return this.formDefinition.key + "help";
    }
    else {
      return "";
    }
  }

  get valueLength(): number {
    if (this.customFormControl === undefined || this.customFormControl.value === null) {
      return 0;
    }

    return this.customFormControl.value.length;
  }

  addMessage(error: ValidationErrors) {
    
    // String Validation 
    if (error?.required !== undefined) {
      this.messages.push(new Message(MessageType.Error, this.formDefinition.label + " must be entered."));
    }

    if (error?.minlength !== undefined) {
      this.messages.push(new Message(MessageType.Error, this.formDefinition.label + " must be at least " + error.minlength.requiredLength + " characters."));
    }

    if (error?.maxlength !== undefined) {
      this.messages.push(new Message(MessageType.Error, this.formDefinition.label + " cannot be more than " + error.maxlength.requiredLength + " characters."));
    }

    // Numeric Validation
    if (error?.min !== undefined) {
      this.messages.push(new Message(MessageType.Error, this.formDefinition.label + " must be greater than " + error.min.min + "."));
    }

    if (error?.max !== undefined) {
      this.messages.push(new Message(MessageType.Error, this.formDefinition.label + " cannot be more than " + error.max.max + "."));
    }

    // Generic Validation
    if (error?.pattern !== undefined) {
      this.messages.push(new Message(MessageType.Error, this.formDefinition.label + " is not valid."));
    }

    if (this.formDefinition.dataType === DataType.Email) {
      if (error?.email !== undefined)
      {
        this.messages.push(new Message(MessageType.Error, this.formDefinition.label + " must be a valid email address."));
      }
    }

  }

}
