
ol.carousel-indicators {
  li {
    background-color: black;
  }
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23090' width='16' height='16' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23090' width='16' height='16' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 36px;
  height: 36px;
}

.award-img-wrapper {
  img {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 8rem;

    @media screen and (max-width: 480px) {
      margin-bottom: 11rem;
    }
  }
}


.award-carousel .carousel-caption {
  @media screen and (max-width: 480px) {
    > h2 {
      font-size: 1.5rem;
    }
  }
}
