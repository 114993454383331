import { Component } from '@angular/core';

@Component({
    selector: 'app-footer-nav',
    templateUrl: './app.footer-nav.component.html',
    styleUrls: ['./app.footer-nav.component.scss'],
    standalone: false
})
export class AppFooterNavComponent {
}
