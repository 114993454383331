<div *ngIf="product">
  <div class="bg-light-grey text-black-70 d-flex align-items-center" >
    <div class="container">
      <div class="d-flex">
        <div class="justify-content-start p-2 w-100"><h3>{{product.title}}</h3></div>
        <div class="justify-content-end flex-shrink-0 p-2">
          <a class="btn btn-success text-white" role="button" aria-pressed="true" href="tel:021-766-0349"><img src="../assets/img/local_phone-white-24dp.svg" alt="Phone" /> 021 766-0349</a>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-1 product-carousel">
    <ngb-carousel *ngIf="product.slideImages.length">
      <ng-template ngbSlide *ngFor="let slideImage of product.slideImages">

        <div class="banner-img-wrapper" [ngStyle]="{'background-image': 'url(' + slideImage.imgsrc + ')'}">
        </div>

        <div class="carousel-caption">
          <h3>{{slideImage.header}}</h3>
          <p>{{slideImage.description}}</p>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
  <br />

  <div class="container pb-3">
    <div class="w-100 mb-5">
      <h3 class="text-center" [innerHTML]="product.header"></h3>
      <p class="text-center" [innerHTML]="product.description"> </p>
    </div>

    <div class="card-group" *ngIf="product.cards.length">
      <div class="card border-0" style="min-width: 12rem; max-width: 20rem" *ngFor="let card of product.cards">
        <div class="d-flex justify-content-center">
          <img class="py-4" [src]="card.imgsrc" [alt]="card.imgalt" />
        </div>
        <div class="card-body">
          <h5 class="card-title" *ngIf="card.header.length" [innerHTML]="card.header"></h5>
          <p class="card-text" *ngIf="card.description.length" [innerHTML]="card.description"></p>
          <p class="card-text" *ngIf="card.footer.length">
            <small class="text-secondary" [innerHTML]="card.footer"> </small>
          </p>
        </div>
      </div>
    </div>

  </div>

  <div class="bg-light-grey text-black-70 py-3" *ngIf="product.footerHeader.length">
    <div class="container">
      <div class="d-flex flex-column">
        <div class="text-center">
          <h4 [innerHTML]="product.footerHeader"></h4>
        </div>
        <div class="text-center">
          <p [innerHTML]="product.footerDescription"></p>
        </div>
      </div>
      <div class="embed-responsive embed-responsive-16by9 my-5" *ngIf="product.footerVideo != null">
        <iframe [src]="footerVideoSafeUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

        </iframe>
      </div>

    </div>

  </div>

  <div class="mt-3" *ngIf="product.footerImage != null">
    <div *ngIf="product.footerImageCardList?.length == 0; else splitFooterImage">
      <div class="picsum-img-wrapper">
        <img [src]="product.footerImage.imgsrc" [alt]="product.footerImage.imgalt" class="d-block w-100 h-50" />
      </div>
    </div>
    <ng-template #splitFooterImage>
      <div class="row">
        <div class="col px-0">
          <div class="d-block h-100" [ngStyle]="{'background-image': 'url(' + product.footerImage.imgsrc + ')'}" style="background-size:cover;"> </div>
        </div>
        <div class="col">
          <div class="card text-white bg-dark-blue border-0 rounded-0" style="min-width: 12rem;" *ngFor="let card of product.footerImageCardList">
            <div class="d-flex align-items-center">

              <div class="p-2"><img [src]="card.imgsrc" [alt]="card.imgalt" /></div>

              <div class="p-2 flex-fill">
                <div class="card-body">
                  <h5 class="card-title" *ngIf="card.header.length" [innerHTML]="card.header"></h5>
                  <p class="card-text" *ngIf="card.description.length" [innerHTML]="card.description"></p>
                  <p class="card-text" *ngIf="card.footer.length">
                    <small class="text-secondary" [innerHTML]="card.footer"> </small>
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </ng-template>
  </div>

</div>
