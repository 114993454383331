import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Award } from '../product';
import { MisAward } from '../mock-awards';

@Component({
    selector: 'app-awards',
    templateUrl: './awards.component.html',
    styleUrls: ['./awards.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class AwardsComponent implements OnInit {
  award: Award;

  ngOnInit(): void {
    this.award = MisAward;
  }
}
