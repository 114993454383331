<div class="bg-light-grey text-black-70 d-flex align-items-center">
  <div class="container">
    <div class="d-flex justify-content-between">
      <div class="p-2"><h3>About Us</h3></div>
      <div class="ml-auto p-2">
        <a class="btn btn-success text-white" role="button" aria-pressed="true" href="tel:021-750-7886"><img src="../assets/img/local_phone-white-24dp.svg" alt="Phone" /> 021 750-7886</a>
      </div>
    </div>
  </div>
</div>

<div class="bg-image-bar" style="background-image: url(https://picsum.photos/id/180/900/400);">
  <div class="container">
    <div class="bg-image-bar-info">
      <div>
        <h2>Simplify Business</h2>
        <p class="pt-2">
          At Microtek Informa Solusindo, we transform the way people think and work, enabling their organisations to thrive with Sage.
          We are a premium solution provider for Sage 300.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="bg-light-grey text-black-70 d-flex align-items-center mt-2 py-4">
  <div class="container text-black-70">
    <h3 class="align-middle pb-2">
      Premium Solution Provider for SAGE 300 in Indonesia
    </h3>

    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs linkNoUnderline link-color-grey">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Who we are</a>
        <ng-template ngbNavContent>
          <h4 class="py-3">
            Microtek Informa Solusindo
          </h4>
          <p>
            Our company provides business software, services and support to small and medium sized businesses over 30 years.
            Thirty years of experience and constant innovation has earned us; an esteemed reputation among small to medium business throughout Indonesia
            especially in Jakarta. Whilst our heritage is in the small business market we also have the experience and expertise to meet the needs of specific industries and larger organisations.
          </p>
          <p>
            Whether it be our financial software enabling better cash flow management, our Customer Relationship Management software helping to build profitable customer relationships or
            our Human Resources and Payroll offerings being used to improve employee performance and ensure legislative compliance, our software and services equip
            our customers to run their businesses more effectively and overcome the challenges of today’s business environment
          </p>
          <p>
            When faced with these challenges businesses need software suppliers in whom they can trust and with whom they can build real relationships for the long-term.
            What makes SAGE different is our people, our complete dedication to doing the right thing by the customer and our commitment to support them to the very best of our ability.
            By maintaining this focus, we have developed a depth and breadth of knowledge that helps insure our clients success with their system investments.
          </p>
          <p>
            We have a dedicated team of experts of address your business needs. Our entire organisation is centered purposefully around SAGE 300. By maintaining this focus,
            we have developed a depth and breadth of knowledge that helps insure our clients success with their system investments.
          </p>
          <p>Anyone can sell you a package of software, but not all of them can back the purchase up with expertise. Our consultants have the depth knowledge and experience in delivering excellence.</p>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>What we do</a>
        <ng-template ngbNavContent>
          <h4 class="py-3">
            Sage is the global market leader for technology that helps small and medium businesses perform at their best. Sage is trusted by millions of customers worldwide to deliver the best cloud technology and support, with our partners, to manage finances, operations, and people.
          </h4>
          <p>
            Like many of the businesses we serve, Sage began as a small business and has grown beyond what seemed imaginable. Today over 13,000 colleagues now support millions of entrepreneurs across 23 countries as they power the global economy.
          </p>
          <p>
            We help drive today’s business builders with a new generation of solutions to manage everything from money to people.
          </p>
          <p>
            Our social and mobile technology provides live information so you can make fast, informed decisions anytime, anywhere in the world. We support our business builders for life by offering choice, support, expertise and innovation. We champion your causes and provide world-leading advice and support when you need it most.
          </p>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Our Story</a>
        <ng-template ngbNavContent>
          <h4 class="py-3">
            Award Winning Solution Provider
          </h4>
          <p>
            We are a premium solution provider for SAGE 300 with more than 30 years of experience working with small, medium and large sized businesses.
          </p>

          <div class="d-flex flex-column">
            <div class="text-center">
              <a routerLink="/awards" class="btn btn-light border rounded mt-5 btn-lg" role="button" aria-pressed="true">Find out about our Awards</a>
            </div>
          </div>

        </ng-template>
      </li>

      <li [ngbNavItem]="4">
        <a ngbNavLink>Software and Solutions</a>
        <ng-template ngbNavContent>
          <h4 class="py-3">
            Our solutions manage accounting, HR, payroll, payments, assets, construction, real estate and enterprise systems.
          </h4>
          <p>
            They travel with business builders wherever they’re needed – in the cloud, on-site or both – offering the freedom to work with mobility.
            Our solutions fit the needs of startup, scale-up and enterprise companies – whether that means staying on top of their finances, empowering their people or unleashing their power to grow.
          </p>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

  </div>
</div>
<div class="bg-dark-green text-white d-flex align-items-center mt-2">
  <div class="container py-5 my-5">
    <div class="d-flex flex-column">
      <div class="text-center">
        <h2>Need to contact Microtek?</h2>
        <h5 class="pt-2">
          We're always on hand to help. We offer many ways for you to get the answers you need quickly over the phone and email.
        </h5>
        <a routerLink="/contact-us" class="btn btn-light border rounded mt-5 btn-lg" role="button" aria-pressed="true">Contact Us</a>
      </div>
    </div>
  </div>
</div>

<div class="text-black-70 d-flex align-items-center mt-2">
  <div class="container py-3 my-3">
    <div class="d-flex flex-column">
      <div class="text-center">
        <h2 class="pb-3">Our Clients</h2>

        <div class="container">
          <div class="row row-image content">
            <div class="col-lg-2">
              <img src="../assets/img/clients/hokben.jpg" loading="lazy" style="height:180px; width:180px" alt="Hoka Hoka Bento">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/boga.jpg" loading="lazy" style="height:240px; width:240px" alt="Boga Group">
            </div>
            <div class="col-lg-2">
              <img src="../assets/img/clients/trc.png" loading="lazy" style="height:73px; width:150px" alt="TRC Indonesia">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/shibaura.jpg" loading="lazy" style="height:73px; width:150px" alt="Shibaura Shearing Indonesia">
            </div>
            <div class="col-lg-2">
              <img src="../assets/img/clients/foseco.jpg" loading="lazy" style="height:168px; width:127px" alt="Foseco Indonesia">
            </div>
          </div>

          <div class="row row-image content">
            <div class="col-lg-2">
              <img src="../assets/img/clients/koito.jpg" loading="lazy" style="height:140px; width:140px" alt="koito">
            </div>
            <div class="col-lg-6">
              <img src="../assets/img/clients/nisshinbo.png" loading="lazy" style="height:90px; width:400px" alt="Nisshinbo">
            </div>
            <div class="col-lg-2">
              <img src="../assets/img/clients/alp.png" loading="lazy" style="height:150px; width:150px" alt="ALP Petro Indonesia">
            </div>
            <div class="col-lg-2">
              <img src="../assets/img/clients/virtus.png" loading="lazy" style="height:100px; width:220px" alt="Virtus Facilty Services">
            </div>
          </div>

          <div class="row row-image content">
            <div class="col-lg-3">
              <img src="../assets/img/clients/yakult.jpg" loading="lazy" style="height:60px; width:180px" alt="Yakult Indonesia Persada">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/omron.png" loading="lazy" style="height:50px; width:160px" alt="Omron Manufacturing Indonesia">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/bosch.jpg" loading="lazy" style="height:73px; width:150px" alt="Rexroth Bosch Group">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/ndt.jpg" loading="lazy" style="height:100px; width:150px" alt="NDT Instruments Indonesia">
            </div>
          </div>

          <div class="row row-image content">
            <div class="col-lg-3">
              <img src="../assets/img/clients/garuda.jpg" loading="lazy" style="height:180px; width:180px" alt="Garuda Food">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/nissin.png" loading="lazy" style="height:60px; width:180px" alt="Nissin">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/meiji.png" loading="lazy" style="height:73px; width:150px" alt="Meiji">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/toshiba.png" loading="lazy" style="height:73px; width:150px" alt="Toshiba">
            </div>
          </div>

          <div class="row row-image content">
            <div class="col-lg-4">
              <img src="../assets/img/clients/hitachi-metal.jpg" loading="lazy" style="height:110px; width:250px" alt="Hitachi Metal">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/showa.png" loading="lazy" style="height:140px; width:210px" alt="Showa">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/tritama.png" loading="lazy" style="height:95px; width:190px" alt="Tritama Niaga Berjaya">
            </div>
            <div class="col-lg-2">
              <img src="../assets/img/clients/nufarm.jpg" loading="lazy" style="height:130px; width:150px" alt="Nufarm Indonesia">
            </div>
          </div>

          <div class="row row-image content">
            <div class="col-lg-4">
              <img src="../assets/img/clients/toyota.png" loading="lazy" style="height:110px; width:250px" alt="Toyota Tatsu Mechanical">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/rheem.jpg" loading="lazy" style="height:140px; width:210px" alt="Rheem Indonesia">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/airbus-heli.png" loading="lazy" style="height:160px; width:160px" alt="Airbus Helicopters">
            </div>
            <div class="col-lg-2">
              <img src="../assets/img/clients/golden-mills.jpg" loading="lazy" style="height:160px; width:160px" alt="Golden Grandmills">
            </div>
          </div>


          <div class="row row-image content">
            <div class="col-lg-3">
              <img src="../assets/img/clients/zitech.jpg" loading="lazy" style="height:110px; width:250px" alt="Zitech Indonesia">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/ppenergi.png" loading="lazy" style="height:180px; width:220px" alt="PP Energi">
            </div>
            <div class="col-lg-3">
              <img src="../assets/img/clients/enkei.png" loading="lazy" style="height:160px; width:260px" alt="Enkei">
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
