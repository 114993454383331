<nav class="navbar navbar-expand-lg static-top navbar-light bg-white ">
  <div>

    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>

    <a class="navbar-brand pl-2" href="#">
      <img src="/assets/logo/MIS_LOGO.png" alt="Microtek Informa Solusindo" height="54px" width="198px" />
    </a>
  </div>
  <div [ngbCollapse]="isMenuCollapsed" class="linkNoUnderline collapse navbar-collapse">
    <div class="">
      <ul ngbNav #nav="ngbNav" class="navbar">
        <li class="nav-item position-static" ngbDropdown display="dynamic" placement="bottom-left">
          <a class="nav-link font-sans-pro" style="cursor: pointer" ngbDropdownToggle id="navbarProductsDropdown" role="button" aria-label="Products Navigation">
            Products
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarProductsDropdown" class="dropdown-menu w-75">
            <div class="card">
              <div class="card-header text-black-50">
                Business Management
              </div>
              <a ngbDropdownItem routerLink="/products/sage300" class="badge-light nav-dropdown-item">
                <div class="row card-body">
                  <div class="col-md-3">
                    <img class="card-img-left" src="/assets/img/sage-accounting.svg" alt="Accounting" />
                  </div>
                  <div class="col-md-9">
                    <h5 class="card-title">Sage 300</h5>
                    <p class="card-text">An end-to end business management solution that unlocks your company's potential, reduce costs and improve performance with enhanced business visibility.</p>
                  </div>
                </div>
              </a>
            </div>

            <div class="card">
              <div class="card-header text-black-50">
                CRM
              </div>
              <a ngbDropdownItem routerLink="/products/sagecrm" class="badge-light nav-dropdown-item">
                <div class="row card-body">
                  <div class="col-md-3">
                    <img class="card-img-left" src="/assets/img/sage-CRM.svg" alt="Customer Relationship Management" />
                  </div>
                  <div class="col-md-9">
                    <h5 class="card-title">Sage CRM</h5>
                    <p class="card-text">
                      With customer relationship management software, you have the tools to find new customers, reduce sales cycles, and build lasting, more profitable relationships.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div class="card">
              <div class="card-header text-black-50">
                HR &amp; Payroll
              </div>
              <a ngbDropdownItem routerLink="/products/hrpay" class="badge-light nav-dropdown-item">
                <div class="row card-body">
                  <div class="col-md-3">
                    <img class="card-img-left" src="/assets/img/sage-HRPay.svg" alt="HR & Payroll" />
                  </div>
                  <div class="col-md-9">
                    <h5 class="card-title">Sage People</h5>
                    <p class="card-text">
                      Comprises an agile, innovative and cost-effective HR solution that effortlessly and powerfully processes HR and payroll functions in your business.
                    </p>
                  </div>
                </div>
              </a>
            </div>

          </div>
        </li>

        <!--<li class="nav-item dropdown position-static" ngbDropdown display="dynamic" placement="bottom-left">
          <a class="nav-link font-sans-pro" style="cursor: pointer" ngbDropdownToggle id="navbarProductsDropdown" role="button" aria-label="Solutions Navigation">
            Solutions
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarProductsDropdown" class="dropdown-menu w-75">
            <div class="card">
              <div class="card-header text-black-50">
                By system
              </div>
              <a ngbDropdownItem routerLink="/products/sage300" class="badge-light nav-dropdown-item">
                <div class="row card-body">
                  <div class="col-md-2">
                    <img class="card-img-left" src="/assets/img/sage-connected.svg" height="48px" width="48px" alt="Cloud" />
                  </div>
                  <div class="col-md-10">
                    <h5 class="card-title">Cloud-based ERP software</h5>
                    <p class="card-text"> Get powerful features on your computer, tablet, and phone, whenever you need them. Allows you to collaborate better across multiple locations, departments, remote employees and even external vendors and accountants.</p>
                  </div>
                </div>
              </a>
              <a ngbDropdownItem routerLink="/products/sage300" class="badge-light nav-dropdown-item">
                <div class="row card-body">
                  <div class="col-md-2">
                    <img class="card-img-left" src="/assets/img/sage-people-network.svg" height="48px" width="48px" alt="On-Premise" />
                  </div>
                  <div class="col-md-10">
                    <h5 class="card-title">On-premises ERP software</h5>
                    <p class="card-text">Deployed in-house and within an own's IT infrastructure and be fully in control.</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </li>-->
        <li ngbNavItem>
          <a ngbNavLink routerLink="/products/sagecover">Sage Cover</a>
        </li>
        <li ngbNavItem>
          <a ngbNavLink routerLink="/support">Support</a>
        </li>
        <li ngbNavItem>
          <a ngbNavLink routerLink="/awards">Awards</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
