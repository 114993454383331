import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-header-nav',
    templateUrl: './app.header-nav.component.html',
    styleUrls: ['./app.header-nav.component.scss'],
    standalone: false
})
export class AppHeaderNavComponent {
  // Create a property to track whether the menu is open.
  // Start with the menu collapsed so that it does not
  // appear initially when the page loads on a small screen!
  public isMenuCollapsed = true;
}
