import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { Product } from './product';
import { Products } from './mock-products';

@Injectable({ providedIn: 'root' })
export class ProductService {

  constructor() { }

  getProducts(): Observable<Product[]> {
    return of(Products);
  }

  getProduct(id: string): Observable<Product> {
    const productItem = of(Products.find(product => product.id === id));

    return productItem;
  }
}
