import { Component } from '@angular/core';

@Component({
    selector: 'app-training',
    templateUrl: './training.component.html',
    styleUrls: ['./training.component.scss'],
    standalone: false
})

export class TrainingComponent {
  active = 1;
}
