import { Product } from './product';

export const Products: Product[] = [
  {
    id: "sage300",
    title: "Business Management - Sage 300",
    slideImages: [
      {
        imgsrc:"../assets/img/banners/image010_A06_1920x780.jpg",
        imgalt: "Simplify your financials",
        header: "Simplify your financials",
        description: "Looking to improve efficiency or effectiveness in your manufacturing, distribution, or retail business?"
      },
      {
        imgsrc: "../assets/img/banners/image010_A03_1920x780.jpg",
        imgalt: "Simplify inventory management",
        header: "Simplify inventory management",
        description: "Ship orders on time, from multiple locations. Simplify your inventory—including shipments, returns, and adjustments—with multi-location inventory tracking."
      },
      {
        imgsrc: "../assets/img/banners/image010_A04_1920x780.jpg",
        imgalt: "Automate your finances",
        header: "Automate your finances",
        description: "Simplify your monthly bank reconciliations. Detect unrecorded transactions, errors and differences, and easily correct to reconcile your books with your bank statements."
      },
      {
        imgsrc: "../assets/img/banners/image010_A05_1920x780.jpg",
        imgalt: "Manage multiple companies and currencies",
        header: "Manage multiple companies and currencies",
        description: "Unlike typical ERP software, Sage 300 makes it easy to set up and manage multiple companies, close books, and report results by company or consolidated company."
      }
    ],
    header: "Prepare your business for growth today",
    description:"Sage Accounting software can help prepare your business for growth. You'll love how easy it is to manage your accounting, inventory, operations, distribution, and more.",
    cards: [
      {
        imgsrc:"../assets/img/financial-services.svg",
        imgalt: "Financial Services",
        header: "Manage Multiple Companies and Currencies",
        description: "Unlike typical ERP software, Sage 300 makes it easy to set up and manage multiple companies, close books, and report results by company or consolidated company.",
        footer: ""
      },
      {
        imgsrc: "../assets/img/arrow-right.svg",
        imgalt: "International Cap",
        header: "Eliminate International Business Complexity",
        description: "With <b> Sage 300 </b>, you can maintain an unlimited number of currencies and exchange rates, get daily updates, and automate the gains or losses from fluctuations.",
        footer: ""
      },
      {
        imgsrc: "../assets/img/happy.svg",
        imgalt: "Simplify",
        header: "Simplify Inventory Management",
        description: "Ship orders on time, from multiple locations. Simplify your inventory—including shipments, returns, and adjustments—with multi-location inventory tracking.",
        footer: ""
      }
    ],
    footerHeader: "Transform the way you tackle Accounts Payable",
    footerDescription: "<p>Do you know the typical AP department manages 77% paper invoices? The cost of processing one invoice is between $18-$22. \
Industry average processing time is 14.2 business days for a single invoice. Why not simplify the way you work? \
</p><p>With Sage AP Automation, you can completely transform the way you tackle Accounts Payable.\
Work with freedom and flexibility from the cloud, save time by automating manual processes, and run your business with more efficiency.</p>",
    footerCards: null,
    footerImage: null,
    footerImageCardList: [],
    footerSlideImages: [],
    footerVideo: "https://www.youtube.com/embed/igFIR_kEuHU",
  },
  {
    id: "sagecrm",
    title: "Sage Customer Relationship Management",
    slideImages: [
      {
        imgsrc: "https://picsum.photos/id/450/900/400",
        imgalt: "Outperform Competition",
        header: "Outperform Competition",
        description: "Companies all around the world are already experiencing the power of an integrated CRM and business management solution.\
Bring together every area of your business including sales, finance, marketing and customer service."
      },
      {
        imgsrc: "https://picsum.photos/id/448/900/400",
        imgalt: "Deliver Better Customer Service",
        header: "Deliver Better Customer Service",
        description: "Service team can resolve queries faster and with more personal responses and see every stage of the customer buying journey."
      },
      {
        imgsrc: "https://picsum.photos/id/497/900/400",
        imgalt: "Send Targeted Marketing Campaigns",
        header: "Send Targeted Marketing Campaigns",
        description: "Using Sage CRM and MailChimp, create targeted campaigns and earn more from your next campaign."
      },
      {
        imgsrc: "https://picsum.photos/id/478/900/400",
        imgalt: "Empowers More Productive Teams",
        header: "Empowers More Productive Teams",
        description: "An integrated solution gives your colleagues what they need to become more productive and avoid duplication of work.\
Customer service teams handle customer queries more efficiently, and sales teams can source quotes faster and meet specific shipment and delivery requirements."
      },
      {
        imgsrc: "https://picsum.photos/id/442/900/400",
        imgalt: "Works Faster and Smarter",
        header: "Works Faster and Smarter",
        description: "Automating work-flows that seamlessly connects one department to the next ensures your business processes run smoothly and efficiently."
      },
      {
        imgsrc: "https://picsum.photos/id/432/900/400",
        imgalt: "Uses Visibility to Drive Profitability",
        header: "Uses Visibility to Drive Profitability",
        description: "Using real-time information about the volume and value of trade in your company, you can identify sales patterns and cross-sell opportunities."
      },
      {
        imgsrc: "https://picsum.photos/id/477/900/400",
        imgalt: "Pitches New Products to the Right People",
        header: "Pitches New Products to the Right People",
        description: "With better business insight, you will be able to leverage reliable customer data and target customers who want your new products and services."
      }
    ],
    header: "Unlock success with your existing Sage solution",
    description: "As a Sage customer, you can choose from fully integrated CRM or Sage Sales, Marketing, and Service modules to suit your business needs.",
    cards: [
      {
        imgsrc: "../assets/img/sage-light-bulb.svg",
        imgalt: "Gain reliable insight",
        header: "Gain Reliable Insight",
        description: "<p>Evaluate where your business stands, and make more informed business decisions you can be confident about.</p>",
        footer: ""
      },
      {
        imgsrc: "../assets/img/sage-compass.svg",
        imgalt: "Better Understand Your Customers",
        header: "Better Understand Your Customers",
        description: "<p>Deliver an exceptional customer experience to improve customer retention and drive revenue growth.</p>",
        footer: ""
      },
      {
        imgsrc: "../assets/img/sage-two-ways.svg",
        imgalt: "Collaborate Effectively",
        header: "Collaborate Effectively",
        description: "<p>Avoid duplication of work tasks, empowering your teams to work faster and smarter. With a single solution, everyone involved becomes more productive and efficient.</p>",
        footer: ""
      },
      {
        imgsrc: "../assets/img/sage-incline.svg",
        imgalt: "Accelerate Team Performance",
        header: "Accelerate Team Performance",
        description: "<p>With access to a wealth of sales information, your salespeople are equipped to sell smarter and more efficiently, never missing out on an important deal.</p>",
        footer: ""
      },
      {
        imgsrc: "../assets/img/sage-checkbox.svg",
        imgalt: "Unlock Success with Your Existing Sage solution",
        header: "Unlock Success with Your Existing Sage solution",
        description: "<p>25% increase in sales? 60% productivity gain?</p> \
                      <p>See how your business management solution can help you accomplish this and more.</p>",
        footer: ""
      },
      {
        imgsrc: "../assets/img/sage-connected.svg",
        imgalt: "Deliver a Better Customer Experience",
        header: "Deliver a Better Customer Experience",
        description: "<p>Nurturing customers and providing them with an excellent customer experience will help you increase profits.</p>",
        footer: ""
      },
    ],
    footerDescription: "How we're helping growing businesses today",
    footerHeader: "Here are just some of the benefits our customers are experiencing.",
    footerCards: null,
    footerVideo: "https://www.youtube.com/embed/DfImTdeTJQ4",
    footerImage: {
      imgsrc: "https://picsum.photos/id/20/300/600",
      imgalt: "",
      header: "Service and Assurance",
      description: "Comprehensive software service and assurance."
    },
    footerImageCardList: [
      {
        imgsrc: "../assets/img/sage-incline-white.svg",
        imgalt: "Increased Revenues",
        header: "Increased Revenues",
        description: "<p>Accelerate response time to customer inquiries with accurate information related to project cost and pricing.</p>",
        footer: ""
      },
      {
        imgsrc: "../assets/img/sage-chart-white.svg",
        imgalt: "Real-time Insights",
        header: "Real-time Insights",
        description: "<p>Provide time and expense tracking through mobile devices to generate real-time insights on completed tasks.</p>",
        footer: ""
      },
      {
        imgsrc: "../assets/img/sage-book-tag-white.svg",
        imgalt: "Improved Business Processes",
        header: "Improved Business Processes",
        description: "Improve accounting processes with automated creation of customer invoices, revenue recognition entries, and budget adjustments.",
        footer: ""
      },
      {
        imgsrc: "../assets/img/sage-financial-app-white.svg",
        imgalt: "Increased Profits",
        header: "Increased Profits",
        description: "Simplify project management and ensure accurate tracking to optimize profitability and keep projects on schedule.",
        footer: ""
      }
    ],
    footerSlideImages: [],
  },
  {
    id: "hrpay", title: "Sage People",
    slideImages: [
      {
        imgsrc: "https://picsum.photos/id/733/900/400",
        imgalt: "Integrated",
        header: "Integrated",
        description: "Single database for all your Payroll and HR data, providing a single source of truth and insights into your organisation. Seamless integration with other Sage accounting applications."
      },
      {
        imgsrc: "https://picsum.photos/id/775/900/400",
        imgalt: "Automation",
        header: "Automation",
        description: "Faster, intuitive and tailored set of tools that help you avoid potential errors and hours lost to manual tasks by automating and streamlining business processes."
      },
      {
        imgsrc: "https://picsum.photos/id/787/900/400",
        imgalt: "Scalable and Configurable",
        header: "Scalable and Configurable",
        description: "Let Sage 300 People do the hard work for you, no matter your size and complexity. Agile, cost-effective, innovative solution with world-class capabilities."
      }
    ],
    header: "Sage 300 People Payroll, HR & ESS",
    description: "An integrated payroll, HR and self-service solution that increases productivity and drives growth in your organisation.",
    cards: [
      {
        imgsrc: "../assets/img/sage-checked.svg",
        imgalt: "People Payroll",
        header: "People Payroll",
        description: "<p>Sage 300 People Payroll functionality will not only ensure your pay run is precise and your business is compliance; but provides powerful tools that enable you to gain valuable insight and connect with your employees.</p>\
          <p>Payroll in 300 People, is packed with great features that put you in control of your payroll processes.</p>\
          <p>API's available for integrations with other solutions.</p>",
        footer: "<a class=\"btn btn-primary\" href=\"https://www.sage.com/en-za/-/media/files/sagedotcom/southafrica/documents/pdf/sage_300_people_payroll_module_datasheet.pdf?la=en-za\ role=\"button\" aria-pressed=\"true\">Download Datasheet</button>"
      },
      {
        imgsrc: "../assets/img/sage-payroll.svg",
        imgalt: "People HR",
        header: "People HR",
        description: "<p>A HR solution that supports your existing and future HR & Payroll processes.  Eliminate repetitive, manual processes and give valuable time back to your HR and People teams so they spend less time on admin and more time focused on strategic activities.</p>\
        <p>The HR functionality offers you a range of tools to manage the entire employee journey.</p>",
        footer: "<a class=\"btn btn-primary\" href=\"https://www.sage.com/en-za/-/media/files/sagedotcom/southafrica/documents/pdf/sage_300_people_human_resources_datasheet.pdf?la=en-za\ role=\"button\" aria-pressed=\"true\">Download Datasheet</button>"
      },
      {
        imgsrc: "../assets/img/sage-connected-blue.svg",
        imgalt: "Self-Service",
        header: "Self-Service",
        description: "Built for Today and Tomorrow.  Reduce data maintenance for your HR and People teams, improve and empower your employees to own their personal data through self-service.  This frees up resources that will allow you to focus on the strategic imperatives of your business instead of mundane administrative issues.",
        footer: "<a class=\"btn btn-primary\" href=\"https://www.sage.com/en-za/-/media/files/sagedotcom/southafrica/documents/pdf/sage_300_people_employee_self_service.pdf?la=en-za\ role=\"button\" aria-pressed=\"true\">Download Datasheet</button>"
      }
    ],
    footerHeader: "Next generation HR, Payroll and ESS in one powerful yet simple solution.",
    footerDescription: "<ul class=\"list-group list-group-flush\"> \
                              <li class=\"list-group-item bg-transparent\">Unlimited employees and companies</li> \
                              <li class=\"list-group-item bg-transparent\">Unlimited hierarchical levels, pay period configurations and pay runs</li> \
                              <li class=\"list-group-item bg-transparent\">Intuitive user interface</li> \
                              <li class=\"list-group-item bg-transparent\">Runs on MS SQL server</li> \
                            </ul> \
                            <br /> \
                            <h4>All the tools you need to drive HR and payroll in large businesses</h4> \
                            <br /> \
                            <h5>Security, stability and improved processes</h5> \
                            <p>With airtight security and rock- solid stability, you get impressive improvements in processing through constant innovation.The design integrates with existing Sage Accounting and BMS applications \
                               and build on the strength of SQL as a unified platform.</p>\
                            <h5>Online real-time design</h5> \
                            <p>Online, real-time design makes your processing easy to monitor, cutting down on errors and reducing risk.\
                               This combination with a period-driven system is unprecedented in the industry, giving you true state-of-the-art technology.</p>\
                            <h5>Smart design means fast, efficient implementation</h5> \
                            <p>The pioneering design ensures complete implementation in a fraction of the time it takes to install other systems of this calibre.</p>\
                            <h5>Universal calculation engine</h5> \
                            <p>You can use this in any design area – from calculating medical aid deductions and printing detailed reports to generating complex job-costing calculations.\
                              It’s just one example of how Sage can gives you cohesive, comprehensive and configurable solutions that break legacy configurations.</p>",
    footerCards: null,
    footerVideo: "https://www.youtube.com/embed/no36K-9eyfg",
    footerImage: null,
    footerImageCardList: [],
    footerSlideImages: [],
  },
  {
    id: "sagecover", title: "Sage Cover",
    slideImages: [
      {
        imgsrc: "https://picsum.photos/id/398/900/400",
        imgalt: "Service and Assurance",
        header: "Service and Assurance",
        description: "Comprehensive software service and assurance."
      },
      {
        imgsrc: "https://picsum.photos/id/305/900/400",
        imgalt: "Protect your investment",
        header: "Protect your investment",
        description: "Because you are always running the most up-to-date software, you do not need to worry that your business-critical software may become obsolete."
      },
      {
        imgsrc: "https://picsum.photos/id/312/900/400",
        imgalt: "Price Protection",
        header: "Price Protection",
        description: "When SRP prices increase, you are already covered with Sage Cover. One year and discounted multi-year subscription terms allow you to choose the degree of price protection that is right for your business – and help simplify your budgeting."
      },
      {
        imgsrc: "https://picsum.photos/id/344/900/400",
        imgalt: "Access to Sage Technical Team",
        header: "Access to Sage Technical Team",
        description: "Without Sage Cover your Sage Business Partner is unable to get support from Sage Technical team on your behalf."
      }
    ],
    header: "Sage Cover - Software Assurance Plan",
    description: "<p>We know how much you depend on your Sage Solution which is why we have made it easy for you to keep your system in top form with our software assurance plan, Sage Cover. \
                     The Sage Cover plan provides software updates, upgrades and information, giving you total investment protection in the long run.</p> \
                  <p>With Sage Cover, you do not need to worry  about keeping up to date with the latest version because it will be made available to you automatically. \
                      Each new version includes features that help your team maximize their productivity and performance. \
                      And best of all, it cost signiﬁcantly  less on an annual basis than sporadically  purchasing new software licenses.</p>",
    cards: [
      {
        imgsrc: "../assets/img/sage-balance.svg",
        imgalt: "Save Money",
        header: "Save Money",
        description: "<p>Just a small percentage of your module SRP compared to buying new licenses of new version at full price. Why pay more for new version when you can have it with Sage Cover for less?</p>",
        footer: ""
      },
      {
        imgsrc: "../assets/img/sage-time.svg",
        imgalt: "Save Time",
        header: "Save Time",
        description: "<p>You need not manually watch out for new  software versions. We, Sage and your Business Partner, will notify you on software updates and will make new versions readily available to you as soon as they are released.</p>",
        footer: ""
      },
      {
        imgsrc: "../assets/img/sage-connected-blue.svg",
        imgalt: "Maxmise Productivity",
        header: "Maxmise Productivity",
        description: "Your software will be insured and protected at all times to ensure its eﬃciency. Thus increase the productivity of your operation.",
        footer: ""
      },
      {
        imgsrc: "../assets/img/sage-app-checked.svg",
        imgalt: "Protect your investment",
        header: "Protect your investment",
        description: "Because you are always running the most up-to-date software, you do not need to worry that your business-critical software may become obsolete.",
        footer: ""
      }
    ],
    footerHeader: "Save with Multi-Year Discounts",
    footerDescription: "<table class=\"table table-striped\"> \
                              <thead class=\"thead-dark\"> \
                                <tr> \
                                  <th scope=\"col\">Purchased Plan</th>\
                                  <th scope=\"col\">Discount</th>\
                                </tr> \
                              </thead> \
                              <tbody> \
                                <tr> \
                                  <th scope=\"col\">2 years</th>\
                                  <th scope=\"col\">Save 5%</th>\
                                </tr> \
                                <tr> \
                                  <th scope=\"col\">3 years</th>\
                                  <th scope=\"col\">Save 10%</th>\
                                </tr> \
                                <tr> \
                                  <th scope=\"col\">5 years</th>\
                                  <th scope=\"col\">Save 15%</th>\
                                </tr> \
                              </tbody> \
                            </table>",
    footerCards: null,
    footerImage: null,
    footerVideo: null,
    footerImageCardList: [],
    footerSlideImages: [],
  },
];
