import { ValidatorFn, Validators, EmailValidator } from "@angular/forms";

export enum DataType {
  String,
  Number,
  Password,
  Email,
  Telephone,
  Url,
  Option,

  Date,
  Datetime,
  Time,  
}

export enum ControlType {
  Textbox,
  Dropdown,
  Date
}

export class FormDefinition{
  value: any;
  key: string;
  label: string;
  minLength: number;
  maxLength: number;
  name: string;
  placeholder: string;
  helpText: string;
  cssClass: string;
  dataType: DataType;
  optionList: {key: string, value: string }[];
  validators: ValidatorFn[];

  constructor(options: {
    value?: any,
    key?: string,
    label?: string,
    name?: string,
    dataType?: DataType,
    placeholder?: string,
    helpText?: string,
    minLength?: number,
    maxLength?: number,
    cssClass?: string,
    validators?: ValidatorFn[],
    optionList?: { key: string, value: string }[];
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.minLength = options.minLength || 0;
    this.maxLength = options.maxLength || 0;
    this.name = options.name || options.key || '';
    this.helpText = options.helpText || '';
    this.placeholder = options.placeholder || '';
    this.cssClass = options.cssClass || '';
    this.dataType = options.dataType || DataType.String;
    this.validators = [];
    this.optionList = options.optionList || [];

    if (options.validators && options.validators.length > 0) {
      this.validators.concat(options.validators);
    }

    // Additional Configuration
    if (this.dataType === DataType.Number) {
      if (this.minLength > 0) {
        this.validators.push(Validators.min(this.minLength));
      }

      if (this.maxLength > 0) {
        this.validators.push(Validators.max(this.maxLength));
      }
    }
    else {
      if (this.minLength > 0) {
        this.validators.push(Validators.required);
        this.validators.push(Validators.minLength(this.minLength));
      }

      if (this.maxLength > 0) {
        this.validators.push(Validators.maxLength(this.maxLength));
      }
    }

    if (this.dataType === DataType.Telephone) {
      this.validators.push(Validators.pattern("^\\s*(?:\\+?(\\d{1,3}))?([-. (]*(\\d{3})[-. )]*)?((\\d{3})[-. ]*(\\d{2,4})(?:[-.x ]*(\\d+))?)\\s*$"));
    }

    if (this.dataType === DataType.Email) {
      this.validators.push(Validators.email);
    }
  }

   get inputType(): string {
     switch (this.dataType) {
       case DataType.Number:
         return "number";
       case DataType.Password:
         return "password";
       case DataType.Email:
         return "email";
       case DataType.Telephone:
         return "tel";
       case DataType.Url:
         return "url";
       case DataType.Date:
         return "date";
       case DataType.Datetime:
         return "datetime-local";
       case DataType.Time:
         return "time";
       default:
         return "text";
     }
  }

  get controlType(): ControlType {
    switch (this.dataType) {
      case DataType.Option:
        return ControlType.Dropdown;
      default:
        return ControlType.Textbox;
    }
  }
}
