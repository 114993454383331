import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { FormDefinition } from './form-definition';
import { FormDefinitions } from './mock-form-definitions';
import { MessageService } from '../message.service';

@Injectable({ providedIn: 'root' })
export class FormService {

  constructor(private messageService: MessageService) { }

  getFormDefinitions(id: string): Observable<FormDefinition[]> {
    const formDefinition = of(FormDefinitions.find(formDefinition => formDefinition.id === id).value);

    return formDefinition;
  }
}
