export class EmailRequestItem {
  SenderEmailAddress: string;
  Subject: string;
  Message: string;
  Token: string;
  UserIpAddress: string;
}

export class EmailResponseItem {
  Success: boolean;
}
