import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { AboutUsComponent } from './static/about-us.component';
import { SupportComponent } from './static/support.component';
import { ContactUsComponent } from './static/contact-us.component';
import { AwardsComponent } from './static/awards.component';
import { TrainingComponent } from './static/training.component';
import { MainComponent } from './dynamic/main.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'products/:id', component: ProductDetailComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'support', component: SupportComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'awards', component: AwardsComponent },
  { path: 'training', component: TrainingComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true }), CommonModule],
  exports: [RouterModule],
  declarations:[]
})

export class AppRoutingModule { }
