<div class="banner-carousel">
  <ngb-carousel>
    <ng-template ngbSlide>
      <div class="banner-img-wrapper" style="background-image: url(../assets/img/banners/image011_A06_1920x780.jpg);">
      </div>
      <div class="carousel-caption left-caption">
        <div class="bg-image-bar-info main-bar p-5">
          <h3>Business Management</h3>
          <p class="py-4 font-weight-bolder">
            Sage 300 is an end-to end business management solution that unlocks your company's potential, reduce costs
            and improve performance with enhanced business visibility.
          </p>
          <a class="btn btn-primary btn-lg" role="button" routerLink="/products/sage300" aria-pressed="true">Explore Sage
            300</a>
        </div>
      </div>
    </ng-template>

    <ng-template ngbSlide>
      <div class="banner-img-wrapper" style="background-image: url(../assets/img/banners/image010_A013_1920x780.jpg);">
      </div>
      <div class="carousel-caption left-caption">
        <div class="bg-image-bar-info main-bar p-5">
          <h3>Customer Relationship Management</h3>
          <p class="py-4 font-weight-bolder">
            Sage CRM is a customer relationship management software, you have the tools to find new customers, reduce
            sales cycles, and build lasting, more profitable relationships.
          </p>
          <a class="btn btn-primary btn-lg" role="button" routerLink="/products/sagecrm" aria-pressed="true">Explore Sage
            CRM</a>
        </div>
      </div>
    </ng-template>

    <ng-template ngbSlide>
      <div class="banner-img-wrapper" style="background-image: url(../assets/img/banners/image010_A012_1920x780.jpg);">
      </div>
      <div class="carousel-caption left-caption">
        <div class="bg-image-bar-info main-bar p-5">
          <h3>HR &amp; Payroll</h3>
          <p class="py-4 font-weight-bolder">
            Sage People is a comprises an agile, innovative and cost-effective HR solution that effortlessly and
            powerfully processes HR and payroll functions in your business.
          </p>
          <a class="btn btn-primary btn-lg" role="button" routerLink="/products/sagecrm" aria-pressed="true">Explore Sage
            People</a>
        </div>
      </div>
    </ng-template>

  </ngb-carousel>
</div>


<div class="bg-dark-green text-white d-flex align-items-center card-transparent card-center">
  <div class="container">
    <div class="row p-2 text-center">
      <div class="col">
        <h4 style="display:block">
          Microtek Informa Solusindo offer various services to help you
        </h4>
      </div>
    </div>

    <div class="card-group">

      <div class="card border-0">
        <img src="../assets/img/sage-book-tag-white.svg" alt="Training" />
        <div class="card-body">
          <h6 class="card-title">Training</h6>
        </div>
      </div>

      <div class="card border-0">
        <img src="../assets/img/sage-people-chat-white.svg" alt="Consultancy" />
        <div class="card-body">
          <h6 class="card-title">Consultancy</h6>
        </div>
      </div>

      <div class="card border-0">
        <img src="../assets/img/sage-financial-app-white.svg" alt="Cost-Saving" />
        <div class="card-body">
          <h6 class="card-title">Cost Saving</h6>
        </div>
      </div>

      <div class="card border-0">
        <img src="../assets/img/sage-rapid-white.svg" alt="Improvement" />
        <div class="card-body">
          <h6 class="card-title">Improvement</h6>
        </div>
      </div>

      <div class="card border-0">
        <img src="../assets/img/sage-app-checked-white.svg" alt="Implementation" />
        <div class="card-body">
          <h6 class="card-title">Implementation</h6>
        </div>
      </div>


    </div>

  </div>
</div>


<div class="bg-light-grey text-black-7 d-flex align-items-center card-transparent card-center">
  <div class="container">
    <div class="embed-responsive embed-responsive-16by9 my-5">
      <iframe src="https://www.youtube.com/embed/K-u4mYXOkG8" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

      </iframe>
    </div>
  </div>
</div>

<div class="container ">
  <div class="py-5 row row-cols-1 row-cols-md-4 g-4 card-center">
    <div class="col ">
      <!-- Platinum Partner -->
      <div class="card border-0 h-100">
        <img class="" src="../assets/img/awards/logo-platinum-elite.png" height="128px" alt="Sage Platinum Elite" />
        <div class="card-body">
          <h5 class="card-title">#1 Asia Platinum Elite Partner for Sage</h5>
        </div>
      </div>
    </div>

    <div class="col">
      <!-- Partner -->
      <div class="card border-0 h-100">
        <img class="" src="../assets/img/awards/logo-partner.png" height="128px" alt="Sage Partner" />
        <div class="card-body">
          <h5 class="card-title">#1 Indonesia Partner for Sage</h5>
        </div>
      </div>
    </div>

    <div class="col">
      <!-- Diamond Partner -->
      <div class="card border-0 h-100">
        <img class="" src="../assets/img/awards/logo-sage-platinum.png" height="60px" alt="Platinum Partner" />
        <div class="card-body">
          <h5 class="card-title">Sage Platinum Partner</h5>
        </div>
      </div>
    </div>

    <div class="col">
      <!-- Sage Awards -->
      <div class="card border-0 h-100">
        <img class="" src="../assets/img/awards/logo-awards-superbrand.png" height="128px" alt="Superbrand Award" />
        <div class="card-body">
          <h5 class="card-title">Sage Superbrands Status 2019</h5>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card border-0 h-100">
        <img class="" src="../assets/img/awards/logo-award_temkin-2017.png" height="128px" alt="Temkin Award" />
        <div class="card-body">
          <h5 class="card-title">Sage Temkin Group Customer Experience Award 2017</h5>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card border-0 h-100">
        <img class="" src="../assets/img/awards/logo-pcmac-award.png" height="128px" alt="PC Magazine" />
        <div class="card-body">
          <h5 class="card-title">PC Magazine Business Choice Award</h5>
        </div>
      </div>
    </div>

  </div>
</div>
