<div>
  <div class="bg-light-grey text-black-70 d-flex align-items-center">
    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="p-2"><h3>Training</h3></div>
        <div class="ml-auto p-2">
          <a class="btn btn-success text-white" role="button" aria-pressed="true" href="tel:021-750-7886"><img src="../assets/img/local_phone-white-24dp.svg" alt="Phone" /> 021 750-7886</a>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-image-bar" style="    background-image: url(../assets/img/banners/image010_A01_1920x480.jpg);">
    <div class="container">
      <div class="bg-image-bar-info">
        <div>
          <h2>Enhance your proficiency</h2>
          <p class="pt-2">
            Expand your knowledge base and advance your skills trained by certified consultant.
            Develop confidence and competence in effectively utilising your Sage solution.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-light-grey text-black-70 py-3">
    <div class="container">
      <div class="d-flex flex-column">
        <div class="text-center">
          <h4>Learning options to meet your needs!</h4>
        </div>
        <div class="text-center">
          <p>
            With over 30 years of exprience, Microtek Informa Solusindo offers a comprehensive portfolio of training solutions for a variety of roles and skills to meet the education requirements of each organisation.

          </p>
        </div>
      </div>
    </div>

    <!-- Training -->
    <div class="row bg-dark-blue my-2">
      <div class="col">
        <div class="container">
          <div class="card text-white bg-dark-blue border-0 rounded-0" style="min-width: 12rem;">
            <div class="d-flex align-items-center">

              <div class="p-2"><img src="../assets/img/sage-icon-crowd-2-white.svg" alt="Training" width="72px" height="72px" /></div>

              <div class="p-2 flex-fill">
                <div class="card-body">
                  <h5 class="card-title">Instructor Led Training</h5>
                  <div class="card-text">
                    <h6>Offering flexible training methods suited to any lifestyle or budget:</h6>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item bg-transparent text-white">Virtual Instructor Led Training (VILT)</li>
                      <li class="list-group-item bg-transparent text-white">In-person Training</li>
                      <li class="list-group-item bg-transparent text-white">Seminars</li>
                      <li class="list-group-item bg-transparent text-white">Self-study training guide</li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container my-5">
      <h4>Take a moment to view a quick video on how Sage Training can help you!</h4>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe src="https://www.youtube.com/embed/NQbMTzXfR1Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>

  <div class="bg-light-grey text-black-70 d-flex align-items-center p-5">
    <div class="container">
      <app-emailus></app-emailus>
    </div>
  </div>
</div>
