import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Product } from '../product';
import { ProductService } from '../product.service';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
    standalone: false
})

export class ProductDetailComponent implements OnInit {
  product: Product;
  footerVideoSafeUrl: SafeResourceUrl;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  paused = false;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (!slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private productService: ProductService,
    public sanitiser: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getProduct();

    // Sanitise the Video Url for displayed
    if (this.product.footerVideo !== null) {
      this.footerVideoSafeUrl = this.sanitiser.bypassSecurityTrustResourceUrl(this.product.footerVideo);
    }
  }

  getProduct(): void {
    this.route.params.subscribe(
      params => {
        const id = params['id'];
        this.productService.getProduct(id)
          .subscribe(product => this.product = product);
      }
    );
  }

  goback(): void {
    this.location.back();
  }
}
