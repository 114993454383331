import { Component } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
    standalone: false
})

export class ContactUsComponent {

  constructor(private modalService: NgbModal) { }

  private _openModal: NgbActiveModal;
  
  open(content) {
   this._openModal = this.modalService.open(content);
  }

  onSucceed(data: any) {
    this._openModal.close("Success form submission.")
  }
}
