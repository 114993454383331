<div>
  <div class="bg-light-grey text-black-70 d-flex align-items-center">
    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="p-2"><h3>Awards</h3></div>
        <div class="ml-auto p-2">
          <a class="btn btn-success text-white" role="button" aria-pressed="true" href="tel:021-750-7886"><img src="../assets/img/local_phone-white-24dp.svg" alt="Phone" /> 021 750-7886</a>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-image-bar" style="background-image: url(../assets/img/banners/image010_A012_1920x780.jpg);">
    <div class="container">
      <div class="bg-image-bar-info">
        <div>
          <h2>Awards</h2>
          <p class="pt-2">
            We're very proud to win various awards over the years – for offering great customer support and for finding innovative solutions. We'll continue to find ways to improve and deliver a world-class service.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-dark-blue d-flex align-items-center mt-2 py-4">
    <div class="container">
      <div class="w-100">
        <h3 class="text-center text-white">Here are some of our awards</h3>
      </div>
    </div>
  </div>

  <div class="d-flex align-items-center mt-2 py-4">
    <div class="container">
      <div class="mt-1 award-carousel">
        <ngb-carousel *ngIf="award.awardImages.length" class="black-carousel">
          <ng-template ngbSlide *ngFor="let awardImage of award.awardImages">
            <div class="award-img-wrapper">
              <img [src]="awardImage.imgsrc" [alt]="awardImage.imgalt" loading="lazy" />
            </div>
            <div class="carousel-caption" style="color: #090;">
              <h2>{{awardImage.header}}</h2>
              <h5>{{awardImage.description}}</h5>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>

    </div>
  </div>
</div>

