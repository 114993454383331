<ng-template #content>
  <div class="bg-dark-blue text-white">
    <div class="modal-header justify-content-between">
      <h5 class="modal-title">{{modal.title}}</h5>
      <button type="button" class="btn btn-primary close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" [innerHTML]="modal.description"></div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-light" ngbAutofocus (click)="activeModal.close('Close click')">Close</button>
    </div>
  </div>
</ng-template>
